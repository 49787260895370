import AgentService from '@/services/agent.service';

const service = new AgentService();

export default {
    name: 'AgentRules',
    components: {},
    props: {
        value: {type: String, default: ''},
        index: {type: Number, default: -1},
        row: {type: Object, default: {}},
        handler: {
            type: Function, default: null
        },
        removeHandler: {
            type: Function, default: null
        }
    },
    data() {
        return {
            user: '',
            agents: []
        }
    },
    computed: {},
    mounted() {
        this.user = this.value;
        this.List();
    },
    methods: {
        async List() {
            const res = await service.list(null, null, null, this.company = this.$getCompany()._id);
            if (res && !res.error) {
                if (res.data.agents) {
                    res.data.agents.forEach(ele => {
                        this.agents.push({email: ele.User.Local.UserEmail, _id: ele.User._id});
                    });
                }
            }

        },

        handlerChange() {
            this.handler(this.user, this.index);
        },

        handlerRemove() {
            this.removeHandler(this.index)
        }
    }
}
