import {mapActions, mapGetters} from 'vuex';

import FeedService from '@/services/feed.service';
const feedService = new FeedService();

export default {
  name: 'match',

  data() {
    return {}
  },

  async mounted() {
    const {feedFileId} = this.$route.params;

    if (feedFileId) {
      await this.setUploadImportFeedFileData();
    }

    if (this.getUploadImportFileData() === null) {
      await this.$router.push({name: 'ImportCarInventoryUpload'})
    }
  },

  methods: {
    ...mapGetters(['getUploadImportFileData']),

    ...mapActions(['setUploadImportFileData']),

    async setUploadImportFeedFileData() {
      const {feedFileId} = this.$route.params;

      if (!feedFileId) {
        await this.setUploadImportFileData(null);
      }

      const res = await feedService.getFeedFile(feedFileId);
      if (res && !res.error) {
        await this.setUploadImportFileData(res.data || null);
      }
    },

    getList() {
      return this.getUploadImportFileData() && this.getUploadImportFileData().elements ? this.getUploadImportFileData().elements : [];
    },

    getSelect() {
      return this.getUploadImportFileData() && this.getUploadImportFileData().forSelect ? this.getUploadImportFileData().forSelect : [];
    },

    getPercent(value) {
      return ((value.stats.withData / value.stats.total) * 100).toFixed(2)
    },

    verifyValue(item) {
      for (let ele of this.getUploadImportFileData().elements) {
        if (ele.name !== item.name && item.value === ele.value) {
          ele.value = "";
          ele.match = false;
        }
      }
    },

    validateBeforeNext(i) {
      if (!i.value || !i.value.length) return false;

      for (const ele of this.getUploadImportFileData().elements) {
        if (ele.value === i.value && ele.name !== i.name) {
          return true;
        }
      }
      return false;
    }
  }
}