import {mapGetters} from 'vuex'
import {bus} from '@/helpers/bus';

export default {
  name: 'review',

  data() {
    return {
      importSettings: {
        overrideCustomer: false,
        customerConsent: true,
      },
    }
  },

  mounted() {
    if (this.getUploadImportFileData() === null) {
      this.$router.push({name: 'ImportDealUpload'})
    }
  },

  methods: {
    ...mapGetters(['getUploadImportFileData']),

    showReview(view = 0) {
      if (this.getUploadImportFileData()) {
        const {elements} = this.getUploadImportFileData();

        return elements.map((ele) => {
          if (!ele.ignore) {
            return {label: ele.value, value: ele.examples[view]};
          }
        });
      }
    },

    handlerChange() {
      bus.$emit('SET_DEAL_SETTINGS', {
        importSettings: this.importSettings
      });
    }
  }
}