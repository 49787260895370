import {mapActions, mapGetters} from 'vuex';

import FeedService from '@/services/feed.service';

const feedService = new FeedService();

export default {
  name: 'match',

  data() {
    return {
      searchQuery: ''
    }
  },

  async mounted() {
    const {feedFileId} = this.$route.params;

    if (feedFileId) {
      await this.setUploadImportFeedFileData();
    }

    if (this.getUploadImportFileData() === null) {
      await this.$router.push({name: 'ImportLeadUpload'})
    }
  },

  computed: {
    importFieldsToMatch() {
      return this.getUploadImportFileData() && this.getUploadImportFileData().elements
          ? this.getUploadImportFileData().elements
          : [];
    },

    dbFieldsToMatch() {
      const forSelect = this.getUploadImportFileData() && this.getUploadImportFileData().forSelect
          ? this.getUploadImportFileData().forSelect : [];

      if (this.searchQuery) {
        return forSelect.filter(item => {
          return this.searchQuery
              .toLowerCase()
              .split(' ')
              .every(sq => item.name.toLowerCase().includes(sq));
        });
      }

      return forSelect;
    },
  },

  methods: {
    ...mapActions(['setUploadImportFileData']),

    ...mapGetters(['getUploadImportFileData']),

    async setUploadImportFeedFileData() {
      const {feedFileId} = this.$route.params;

      if (!feedFileId) {
        await this.setUploadImportFileData(null);
      }

      const res = await feedService.getFeedFile(feedFileId);
      if (res && !res.error) {
        await this.setUploadImportFileData(res.data || null);
      }
    },

    selectField(field, el) {
      el.value = field.name;
      el.ignore = false;
      el.match = false;

      this.$hideModals();
      this.search = '';
    },

    verifyValue(item) {
      for (let ele of this.getUploadImportFileData().elements) {
        if (ele.name !== item.name && item.value === ele.value) {
          ele.value = "";
          ele.match = false;
        }
      }
    },

    validateBeforeNext(i) {
      if (!i.value || !i.value.length) return false;

      for (const ele of this.getUploadImportFileData().elements) {
        if (ele.value === i.value && ele.name !== i.name) {
          return true;
        }
      }
      return false;
    },

    calcPercent(element) {
      const fractionDigits = element.stats.withData < element.stats.total ? 2 : 0;
      return this._percent(element.stats.total, element.stats.withData, fractionDigits) + '%'
    },

    _percent(total, number, fractionDigits = 0) {
      return (number * 100 / total || 0).toFixed(fractionDigits);
    },
  },

  filters: {
    formatNumber(val) {
      return parseInt(val).toLocaleString();
    }
  }
}