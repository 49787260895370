import {mapActions, mapGetters} from 'vuex';
import {assign} from "lodash";

import FileService from '@/services/file.service';
import AgentRules from '@/components/Rules/Agent';

const fileService = new FileService();

export default {
    name: 'upload',

    components: {AgentRules},

    data() {
        return {
            file: '',
            rules: [],
            steps: 'Upload',
            status: ''
        }
    },

    mounted() {
        this.setUploadImportFileData([{}]);
    },

    methods: {
        ...mapActions(['setUploadImportFileData']),

        ...mapGetters(['getUploadImportFileData']),

        setFile(event) {
            let reader = event.target.files;
            if (reader.length > 0) {
                this.file = reader[0];
            }
            this.status = 'loading-1';
            this.upload();
        },

        async upload() {
            const formData = new FormData();
            formData.append('file', this.file);

            this.status = 'loading-2';

            const res = await fileService.readFile(formData);
            if (res && !res.error) {
                this.file = assign(this.file, res.data.file);
                this.setUploadImportFileData(res.data || [{}]);
                this.status = 'completed'
            } else {
                this.status = 'error';
            }
        },

        removeFile() {
            fileService.removeFile(this.file.path);
            this.setUploadImportFileData(null);
            this.file = '';
            this.rules = [];
        },

        addAgentRule() {
            if (this.rules.findIndex(ele => ele.type === 'agent') === -1) {
                this.rules.push({type: 'agent', agent_id: null, title: this.$t('set_agent')});
                this.persistRules();
            } else {
                this.$notify({
                    type: 'alert', message: this.$t('rule_established')
                })
            }
        },

        setAgent(value, index) {
            if (index > -1) {
                this.rules[index].agent_id = value;
                this.persistRules();
            }
        },

        removeHandler(index) {
            if (index > -1) {
                this.rules.splice(index, 1);
                this.persistRules();
            }
        },

        persistRules() {
            let aux = this.getUploadImportFileData();
            aux.rules = this.rules;
            this.setUploadImportFileData(aux);
        }
    }
}