import {mapGetters} from 'vuex';
import {bus} from '@/helpers/bus';
import InventoryService from '@/services/inventory.service';

const service = new InventoryService();
export default {
  name: 'bodies',
  components: {},
  props: [],
  data() {
    return {
      list: [],
      bodies: null
    }
  },
  watch: {
    bodies: {
      deep: true,
      handler: (newVal) => {
        let aux = true;
        for (const body of newVal) {
          if (body.value === "" || body.match === false) {
            aux = false
            break;
          }
        }
        bus.$emit('passBodyToReview', aux);
      }
    }
  },
  computed: {},
  mounted() {
    if (this.getUploadImportFileData() === null) {
      this.$router.push({name: 'ImportCarInventoryUpload'})
    } else {
      this.bodyList();
      this.bodiesByField();
    }
  },
  methods: {
    ...mapGetters([
      'getUploadImportFileData'
    ]),

    async bodyList() {
      const res = await service.MappedBodies();
      if (res && !res.error) {
        const own_bodies = res.data;
        for (const body of own_bodies) {
          this.list.push({
            name: body,
            value: body
          });
        }
      }
    },

    async bodiesByField() {
      const fields = this.getBodyFieldMatched();
      if (fields) {
        const res = await service.GetBodiesMapByField(this.getUploadImportFileData().file.path, this.getUploadImportFileData().file.extension, fields);
        if (res && !res.error) {
          if (res.data.bodies === 0) {
            bus.$emit('passBodyToReview', true);
          } else {
            this.bodies = res.data.bodies;
            bus.$emit('passBodyToReview', false);
          }
        }
      }
    },

    getBodyFieldMatched() {
      let body = null;
      let model = null;

      for (const ele of this.getUploadImportFileData().elements) {
        if (ele.value.includes('Body')) {
          body = ele.name;
        }

        if (ele.value.includes('Model')) {
          model = ele.name;
        }

        if (body && model)
          return [body, model];
      }
      return null;
    },

    async setMapBody(i) {
      const res = await service.SetBody({body: i.value, match: i.name});
      if (res && !res.error) {
        this.$notify({
          type: 'success',
          message: this.$t('body_set')
        });
      } else {
        this.$notify({
          type: 'error',
          message: this.$t('body_set_error')
        });
      }
    },

    sendBadBody() {
      bus.$emit('passBodyToReview', false)
    }
  }
}


