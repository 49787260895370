import {mapActions} from 'vuex';

import InventoryService from "@/services/inventory.service";
const inventoryService = new InventoryService();

export default {
  name: 'upload',

  data() {
    return {
      file: '',
      steps: 'Upload',
      status: ''
    }
  },

  async mounted() {
    await this.setUploadImportFileData(null);
  },

  methods: {
    ...mapActions(['setUploadImportFileData']),

    async setFile(event) {
      const reader = event.target.files;
      if (reader.length > 0) {
        this.file = reader[0];
      }

      this.status = 'loading-1';
      await this.upload();
    },

    async upload() {
      const formData = new FormData();
      formData.append('file', this.file);

      this.status = 'loading-2';

      const res = await inventoryService.ReadCarFile(formData);
      if (res && !res.error) {
        await this.setUploadImportFileData(res.data || null);
        this.status = 'completed';

      } else {
        this.status = 'error';
      }
    },

    async removeFile() {
      await this.setUploadImportFileData(null);
      this.file = '';
    }
  },
}


