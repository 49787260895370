import {mapActions, mapGetters} from 'vuex';
import {assign} from "lodash";

import FileService from '@/services/file.service';
const fileService = new FileService();

export default {
  name: 'upload',

  data() {
    return {
      file: '',
      rules: [],
      steps: 'Upload',
      status: ''
    }
  },

  mounted() {
    this.setUploadImportFileData(null);
  },

  methods: {
    ...mapActions(['setUploadImportFileData']),

    ...mapGetters(['getUploadImportFileData']),

    setFile(event) {
      let reader = event.target.files;
      if (reader.length > 0) {
        this.file = reader[0];
      }
      this.status = 'loading-1';
      this.upload();
    },

    async upload() {
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('type', 'deal');

      this.status = 'loading-2';

      const res = await fileService.readFile(formData);
      if (res && !res.error) {
        this.file = assign(this.file, res.data.file);
        this.setUploadImportFileData(res.data || [{}]);
        this.status = 'completed'
      } else {
        this.status = 'error';
      }
    },

    removeFile() {
      fileService.removeFile(this.file.path);
      this.setUploadImportFileData(null);
      this.file = '';
      this.rules = [];
    }
  }
}