import {mapGetters} from 'vuex'

export default {
  name: 'review',
  components: {},
  props: [],
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {
    if(this.getUploadImportFileData() === null) {
      this.$router.push({name: 'ImportCarInventoryUpload'})
    }
  },
  methods: {
    ...mapGetters([
        'getUploadImportFileData'
    ]),

    showReview(view= 0) {
      if(this.getUploadImportFileData()) {
        const {elements} = this.getUploadImportFileData();

        return elements.map((ele) => {
          if(!ele.ignore) {
            return {label: ele.value, value: ele.examples[view]};
          }
        });
      }
    }
  }
}


