import IService from './iservice';
import config from "@/config";
import store from "@/store/store";

const server_base = config[config.stage].data_server;

class DataService extends IService {
  constructor(http) {
    super(http);
    this.store = store;
  }

  async getFeedFile(ftpFileId) {
    try {
      this.addLoading('feed-file');
      const response = await this.http.get(`${server_base}/feed/${ftpFileId}`);
      this.delLoading('feed-file');
      return response.data;
    } catch (error) {
      this.delLoading('feed-file');
      return null;
    }
  }
}

export default DataService;