import {mapActions, mapGetters} from 'vuex';
import CompanyService from '@/services/company.service.js';
import FileService from '@/services/file.service.js';

const companyService = new CompanyService();
const fileService = new FileService();
export default {
    name: 'match',
    components: {},
    props: [],
    data() {
        return {
            feedCompany: [],
            filename: ''
        }
    },
    computed: {},
    mounted() {
        if(this.$route.params.file) {
            this.filename = this.$route.params.file;
            this.getFields()
        }
    },
    methods: {
        ...mapGetters([
            'getUploadImportFileStep',
            'getUploadImportFileData'
        ]),
        ...mapActions([
            'setUploadImportFileData'
        ]),

        getList() {
            return this.getUploadImportFileData() && this.getUploadImportFileData().elements ? this.getUploadImportFileData().elements : [];
        },

        getSelect() {
            return this.getUploadImportFileData() && this.getUploadImportFileData().forSelect ? this.getUploadImportFileData().forSelect : [];
        },

        getPercent(value) {
            return ((value.stats.withData / value.stats.total) * 100).toFixed(2)
        },

        verifyValue(item) {
            for (let ele of this.getUploadImportFileData().elements) {
                if (ele.name !== item.name && item.value === ele.value) {
                    ele.value = "";
                    ele.match = false;
                }
            }
        },

        validateBeforeNext(i) {
            if (!i.value || !i.value.length) return false;

            for (const ele of this.getUploadImportFileData().elements) {
                if (ele.value === i.value && ele.name !== i.name) {
                    return true;
                }
            }
            return false;
        },

        async getFields() {
            const res = await fileService.ReadFtpFile(this.filename);
            if (res && !res.error) {
                this.setUploadImportFileData(res.data !== null ? res.data : [{}]);
            }
        },

        async LoadCompanyConfig() {
            const resFeed = await companyService.getFeedConfig('Car');
            if (resFeed && !resFeed.error)
                this.feedCompany = resFeed.data;
        },
    }
}


